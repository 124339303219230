import Link from 'next/link'
import React from 'react'

interface FAQItem {
  question: string
  answer: string | React.ReactNode
}

interface FAQProps {
  faqs: FAQItem[]
}

const FAQ: React.FC<FAQProps> = ({ faqs }) => {
  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl px-6 py-12 sm:pt-16 lg:px-8'>
        <div className='lg:grid lg:grid-cols-12 lg:gap-8'>
          <div className='lg:col-span-5'>
            <h2 className='text-2xl font-bold leading-10 tracking-tight text-gray-900'>
              Frequently asked questions
            </h2>
            <p className='mt-4 text-base leading-7 text-gray-600'>
              Can&apos;t find the answer you&apos;re looking for? Reach out to
              our{' '}
              <Link
                href='/contact'
                className='font-semibold text-indigo-600 hover:text-indigo-500'
              >
                customer support
              </Link>{' '}
              team.
            </p>
          </div>
          <div className='mt-10 lg:col-span-7 lg:mt-0'>
            <dl className='space-y-10'>
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className='text-base font-semibold leading-7 text-gray-900'>
                    {faq.question}
                  </dt>
                  <dd className='mt-2 text-base leading-7 text-gray-600'>
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
